import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useLvr } from "../../../../hooks/scenario.hooks";
import { convertToInt } from "../../../../utils/convert-number";
import { isNotEmptyObject } from "../../../../utils/object-util";
import { titleCase } from "../../../../utils/text-format";

const ScenarioPropertyItem = ({
  addressDetails = {},
  propertyId = "",
  estimateProperty = "",
  loanListData = [],
  selectedScenarioProperty,
  setSelectedScenarioProperty,
  handleSelectScenarioProperty,
}) => {
  const {
    locality = "",
    postcode = "",
    state = "",
    street = "",
    streetNumber = "",
    unitNumber = "",
    streetNumberHigh = "",
  } = addressDetails;
  const [propertyValue, setPropertyValue] = useState({});
  const address = `${unitNumber ? `${unitNumber} / ` : ""}${streetNumber}${
    streetNumberHigh ? ` - ${streetNumberHigh}` : ""
  } ${street} Street,`;
  const suburb = `${titleCase(locality)}, ${state}, ${postcode}`;
  const handleClickProperty = () => {
    setSelectedScenarioProperty(propertyId);
    handleSelectScenarioProperty(propertyValue);
  };
  const totalLoan = useMemo(() => {
    if (loanListData.length > 0) {
      return loanListData.reduce((result, init) => {
        const { balance } = init;
        const tempResult = result + convertToInt(balance);
        return tempResult;
      }, 0);
    }
    return 0;
  }, [loanListData]);

  const lvr = useLvr(estimateProperty, estimateProperty - Math.abs(totalLoan));
  useEffect(() => {
    setPropertyValue({
      ...propertyValue,
      propertyId,
      lvrSetting: {
        lvr,
        estimateProperty,
        cashTowardProperty: estimateProperty - Math.abs(totalLoan),
      },
    });
  }, [estimateProperty, lvr, propertyId, totalLoan]);

  return (
    <div
      className={classNames("scenario-property-filter is-cursor", {
        selected:
          selectedScenarioProperty && selectedScenarioProperty === propertyId,
      })}
      role="button"
      onClick={handleClickProperty}
      onKeyPress={() => {}}
      tabIndex="0"
      id={propertyId}
    >
      <div className="property-account-container">
        {isNotEmptyObject(addressDetails) && (
          <div className="property-item-wrapper">
            <div className="property-address">{titleCase(address)}</div>
            <div className="property-suburb">{suburb}</div>
          </div>
        )}
        {loanListData.length > 0 &&
          loanListData.map((item, index) => {
            const {
              accountId,
              institutionLogo: logo = "",
              name = "",
              balance: value = 0,
            } = item;
            return (
              <div
                style={{ marginLeft: index === 0 ? "0" : "2rem" }}
                className="property-wrapper"
                key={accountId}
              >
                <div className="la-logo">
                  <img src={logo} alt="Home Loan Logo" />
                </div>
                <div className="linked-account-item-value">
                  <div>{name}</div>
                  {value !== 0 && (
                    <NumberFormat
                      value={value}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ScenarioPropertyItem;
